import Layout from '@/layouts'

const reportDoctors = {
  path: '/reportDoctors',
  component: Layout,
  name: 'reportDoctors',
  redirect: '/report/report-doctors',
  children: [
    {
      path: '/report/report-doctors',
      component: () => import('@/views/report/report-doctors'),
      name: 'reportDoctors/index',
      meta: { title: 'Типы расхода'}
    },
  ]
}

export default reportDoctors