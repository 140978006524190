import Layout from '@/layouts'

const usersRouter = {
  path: '/users',
  component: Layout,
  name: 'users',
  redirect: '/users/index',
  children: [
    {
      path: '/users/index',
      component: () => import('@/views/users/index'),
      name: 'users.index',
      meta: { title: 'users'}
    },
    // {
    //   path: 'users/roles',
    //   component: () => import('@/views/users/roles'),
    //   name: 'roles',
    //   meta: { title: 'roles'}
    // },
  ]
}

export default usersRouter