
export const mutations = {
    SET_LIST: (state, items) => {
        state.list = items
    },
    SET_SERVICE: (state, services) => {
        state.services = services
    },


};
