import Layout from '@/layouts'

const patientResidenceReport = {
  path: '/patientResidenceReport',
  component: Layout,
  name: 'patientResidenceReport',
  redirect: '/patientResidenceReport/index',
  children: [
    {
      path: '/patientResidenceReport/index',
      component: () => import('@/views/patientResidenceReport/index'),
      name: 'patientResidenceReport.index',
      meta: {
        title: 'График'
      }
    }
  ]
}

export default patientResidenceReport