import Layout from '@/layouts'

const state = {
    path: '/statuses',
    component: Layout,
    name: 'statuses',
    redirect: '/statuses/index',
    children: [
      {
        path: '/statuses/index',
        component: () => import('@/views/status/index'),
        name: 'statuses.index',
        meta: { title: 'Статус'}
      },
    ]
  }

export default state
