import { index, show, relation_show, paymentStore, store, update, updateStatus, contractPayments, destroy} from '../../../api/payments';

export const actions = {

    index({commit}, params = {}) {
        return new Promise((resolve, reject) => {
            index(params).then(res => {
                commit("SET_LIST", res.data.data.orders.data);
                commit("SET_PRICE", res.data.data.daily_report);
                commit("UPDATE_PAGINATION",{key: 'total', value: res.data.data.orders.pagination.total});
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    show({commit},id) {
        return new Promise((resolve,reject) => {
            show(id).then(res => {
                commit("SET_MODEL",res.data.data.order);
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    relationShow({commit},data) {
        return new Promise((resolve,reject) => {
            relation_show(data).then(res => {
                commit("SET_ORDER_MODEL",res.data.data.order);
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    updateStatus({commit},data) {
        return new Promise((resolve,reject) => {
            updateStatus(data).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    // patientStatusUpdate({commit},data) {
    //     return new Promise((resolve,reject) => {
    //         patientStatusUpdate(data).then(res => {
    //             resolve(res.data)
    //         }).catch(err => {
    //             reject(err.response.data)
    //         })
    //     })
    // },

    store({commit},data) {
        return new Promise((resolve,reject) => {
            store(data).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    paymentStore({commit},data) {
        return new Promise((resolve,reject) => {
            paymentStore(data).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    contractPayments({commit},data) {
        return new Promise((resolve,reject) => {
            contractPayments(data).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    update({commit},data) {
        return new Promise((resolve,reject) => {
            update(data).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    destroy({commit},id) {
        return new Promise((resolve,reject) => {
            destroy(id).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    updateSort({commit}, sort){
        commit("SET_SORT",sort)
    },

    updateFilter({commit}, filter){
        commit('SET_FILTER', JSON.parse( JSON.stringify( filter)))
    },

    updateColumn({commit}, obj){
        commit('UPDATE_COLUMN', obj)
    },

    updatePagination({commit}, pagination){
        commit('UPDATE_PAGINATION', pagination )
    },

    refreshData({commit}){
        return new Promise((resolve, reject) => {
            commit("REFRESH");
            resolve()
        })
    },
    empty({ commit }) {
        return new Promise((resolve, reject) => {
            commit("EMPTY_MODEL");
            resolve()
        })
    },


}
