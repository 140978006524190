import { getDoctor, getPartnerClinic, getPartnerDoctor, store } from '../../../api/bonusService';

export const actions = {

    getDoctor({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            getDoctor(params).then(res => {
                commit("SET_LIST", res.data.data.doctors);
                commit("SET_SERVICE", res.data.data.services);
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    getPartnerClinic({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            getPartnerClinic(params).then(res => {
                commit("SET_LIST", res.data.data.partner_clinics);
                commit("SET_SERVICE", res.data.data.services);
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    getPartnerDoctor({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            getPartnerDoctor(params).then(res => {
                commit("SET_LIST", res.data.data.partner_doctors);
                commit("SET_SERVICE", res.data.data.services);
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    store({ commit }, data) {
        return new Promise((resolve, reject) => {
            store(data).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

}
