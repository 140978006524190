import Layout from '@/layouts'

const specialty = {
    path: '/specialties',
    component: Layout,
    name: 'specialties',
    redirect: '/specialties/index',
    children: [
      {
        path: '/specialties/index',
        component: () => import('@/views/specialty/index'),
        name: 'specialties.index',
        meta: { title: 'Специальности'}
      },
    ]
  }

export default specialty
