import Layout from '@/layouts'

const doctorSign = {
  path: '/doctorSign',
  component: Layout,
  name: 'doctorSign',
  redirect: '/doctorSign/index',
  children: [{
    path: '/doctorSign/index',
    component: () => import('@/views/doctorSign/index'),
    name: 'doctorSign.index',
    meta: {
      title: 'Отчет'
    }
  }, ]
}

export default doctorSign