export const getters = {
    schedulePatients: state => state.schedulePatients,
    stasionaryPatients: state => state.stasionaryPatients,
    paymentsSum: state => state.paymentsSum,
    expences: state => state.expences,
    contractsCount: state => state.contractsCount,
    formTemplates: state => state.formTemplates,
    patientScheduleCount: state => state.patientScheduleCount,
    stasionaryPayments: state => state.stasionaryPayments,
    warehouseCount: state => state.warehouseCount,
    number_of_orders_today: state => state.number_of_orders_today,
    number_of_orders_month: state => state.number_of_orders_month
};
