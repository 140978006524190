import Layout from '@/layouts'


const department = {
  path: '/department',
  component: Layout,
  name: 'department',
  redirect: '/department/index',
  children: [
    {
      path: '/department/index',
      component: () => import('@/views/department/index'),
      name: 'department.index',
      meta: { title: 'Города'}
    },
  ]
}
export default department