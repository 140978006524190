import { i18n } from '@/utils/i18n';

export const columns = {
    id: {
        show: true,
        title: "№",
        sortable: true,
        column: 'id'
    },
  
    hospitalPatient: {
        show: true,
        title: i18n.t('message.first_name'),
        sortable: true,
      
        column: 'hospitalPatient'
    },
    
    surname: {
        show: true,
        title: i18n.t('message.surname'),
        sortable: true,
        column: 'surname'
    },

    dob: {
        show: true,
        title: i18n.t('message.dob'),
        sortable: true,
        column: 'dob'
    },
    
    code: {
        show: true,
        title: i18n.t('message.code_id'),
        sortable: true,
        column: 'code'
    },
    
    room: {
        show: true,
        title: i18n.t('message.room'),
        sortable: true,
        column: 'room'
    },
    
    gender: {
        show: true,
        title: i18n.t('message.gender'),
        sortable: true,
        column: 'gender'
    },
    
    button: {
        show: true,
        title:  i18n.t('message.view_0'),
        sortable: true,
        column: 'button'
    },
    data_arrive: {
        show: true,
        title: i18n.t('message.arrival_date'),
        sortable: true,
        column: 'data_arrive'
    },
    data_departure: {
        show: true,
        title: i18n.t('message.departure_date'),
        sortable: true,
        column: 'data_departure'
    },
    // patient_schedule_id: {
    //     show: true,
    //     arrive: "Дата прибытия",
    //     departure: "Дата отъезда",
    //     sortable: true,
    //     column: 'patient_schedule_id'
    // },
    created_at: {
        show: true,
        title: i18n.t('message.date'),
        sortable: true,
        column: 'created_at'
    },
    updated_at: {
        show: true,
        title: i18n.t('message.update_date'),
        sortable: true,
        column: 'updated_at'
    },
    settings: {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    }
};
