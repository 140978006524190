import Layout from '@/layouts'

const dailyProcessReport = {
  path: '/dailyProcessReport',
  component: Layout,
  name: 'dailyProcessReport',
  redirect: '/dailyProcessReport/index',
  children: [{
    path: '/dailyProcessReport/index',
    component: () => import('@/views/dailyProcessReport/index'),
    name: 'dailyProcessReport.index',
    meta: {
      title: 'Отчет'
    }
  }, ]
}

export default dailyProcessReport