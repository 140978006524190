import Layout from '@/layouts'

const paymentType = {
  path: '/measurementsWarehouses',
  component: Layout,
  name: 'measurementsWarehouses',
  redirect: '/measurementsWarehouses/index',
  children: [{
    path: '/measurementsWarehouses/index',
    component: () => import('@/views/measurement/index'),
    name: 'measurementsWarehouses.index',
    meta: {
      title: 'Тип Группы'
    }
  }, ]
}

export default paymentType