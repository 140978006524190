<template>
  <el-select
    :value="selected"
    @input="dispatch"
    filterable
    clearable
    :placeholder="plc || 'Врач'"
    :size="size"
     class="d-block"
     @change="changed()"
  >
    <el-option
      v-for="item in doctor_list"
      :key="item.id"
      :label="(item.surname ? item.surname : '') + ' ' + (item.name ? item.name : '')"
      :value="item.id"
    ></el-option>
  </el-select>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import inventory from '@/utils/mixins/components/inventory';

export default {
  mixins: [
    inventory
  ],
  data(){
    return {
      doctor_id: null
    }
  },
  mounted() {
    if (this.doctor_list && this.doctor_list.length === 0) {
      this.getInventory();
    }
  },
 
    watch: {
    doctor_id: {
      handler: function () {
        this.dispatch(this.doctor_id);
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    ...mapGetters({
      doctor_list: 'users/doctor_list'
    })
  },
  methods: {
    ...mapActions({
      getInventory: 'users/doctor_list'
    }),
    changed() {
      this.$emit('changed');
    }
    
  }
  
}
</script>