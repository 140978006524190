import Layout from '@/layouts'

const categoryService = {
  path: '/partnerDoctorReport',
  component: Layout,
  name: 'partnerDoctorReport',
  redirect: '/partnerDoctorReport/index',
  children: [
    {
      path: '/partnerDoctorReport/index',
      component: () => import('@/views/partnerDoctorReport/index'),
      name: 'partnerDoctorReport.index',
      meta: { title: 'Партнер доктор'}
    },
  ]
}

export default categoryService
