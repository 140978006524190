import { i18n } from '@/utils/i18n';

export const columns = {
    id: {
        show: true,
        title: "№",
        sortable: true,
        column: 'id'
    },
    name: {
        show: true,
        title: i18n.t('message.name'),
        sortable: true,
        column: 'name'
    },
    code: {
        show: true,
        title: i18n.t('message.code'),
        sortable: true,
        column: 'code'
    },
    life_time: {
        show: true,
        title: i18n.t('message.life_time'),
        sortable: true,
        column: 'life_time'
    },
    price: {
        show: true,
        title: i18n.t('message.current_price'),
        sortable: true,
        column: 'price'
    },
    color: {
        show: true,
        title: i18n.t('message.color'),
        sortable: true,
        column: 'color'
    },
    discount_price: {
        show: true,
        title: i18n.t('message.discount_cost'),
        sortable: true,
        column: 'discount_price'
    },
    is_service: {
        show: false,
        title:  i18n.t('message.category_or_service'),
        sortable: true,
        column: 'is_service'
    },
    parent_id: {
        show: true,
        title: i18n.t('message.parent_service'),
        sortable: true,
        column: 'parent_id'
    },
    created_at: {
        show: true,
        title: i18n.t('message.date'),
        sortable: true,
        column: 'created_at'
    },
    updated_at: {
        show: false,
        title: i18n.t('message.update_date'),
        sortable: true,
        column: 'updated_at'
    },
    settings: {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    }
};
