import { sort } from './../properties/sort';
import { pagination } from './../properties/pagination';
import { filter } from './properties/filter';
import { model } from './properties/model';
import { i18n } from '@/utils/i18n';

export const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_FILTER: (state, filter) => {
    state.filter = filter;
  },
  SET_SORT: (state, sort) => {
    state.sort = sort;
  },
  SET_MODEL: (state, model) => {
    state.model = model;
    state.model.parent_id = model.parent ? model.parent.id : null;
  },
  SET_INVENTORY: (state, inventory) => {
    state.inventory = inventory;
  },
  UPDATE_COLUMN: (state, column) => {
    state.columns[column.key].show = column.value;
  },
  UPDATE_SORT: (state, sort) => {
    state.sort = sort;
  },
  SET_PAGINATION: (state,pagination) => (state.pagination = pagination),
  UPDATE_PAGINATION: (state, pagination) => {
    state.pagination[pagination.key] = pagination.value;
  },
  EMPTY_MODEL: (state) => {
    state.model = JSON.parse(JSON.stringify(model));
  },
  REFRESH: (state) => {
    state.filter = JSON.parse(JSON.stringify(filter));
    state.sort = JSON.parse(JSON.stringify(sort));
    state.pagination = JSON.parse(JSON.stringify(pagination));
  },
  UPDATE_LANG_COLUMN: (state) => {
  
        state.columns.id.title= '№';
        state.columns.name.title=  i18n.t('message.name');
        state.columns.parent.title= i18n.t('message.parent_category');
        state.columns.created_at.title= i18n.t('message.date');
        state.columns.updated_at.title= i18n.t('message.update_date');
        state.columns.settings.title= i18n.t('message.settings');
   
  }
};
