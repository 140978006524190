import Layout from '@/layouts'

const social = {
    path: '/socials',
    component: Layout,
    name: 'socials',
    redirect: '/socials/index',
    children: [
      {
        path: '/socials/index',
        component: () => import('@/views/social/index'),
        name: 'socials.index',
        meta: { title: 'Источник'}
      },
    ]
  }

export default social
