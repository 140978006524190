import Layout from '@/layouts'

const patientServiceReport = {
  path: '/patientServiceReport',
  component: Layout,
  name: 'patientServiceReport',
  redirect: '/patientServiceReport/index',
  children: [
    {
      path: '/patientServiceReport/index',
      component: () => import('@/views/patientServiceReport/index'),
      name: 'patientServiceReport.index',
      meta: {
        title: 'График'
      }
    }
  ]
}

export default patientServiceReport