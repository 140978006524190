import Layout from '@/layouts'

const excelReport = {
  path: '/excelReports',
  component: Layout,
  name: 'excelReports',
  redirect: '/excelReports/index',
  children: [
    {
      path: '/excelReports/index',
      component: () => import('@/views/excelReport/index'),
      name: 'excelReports.index',
      meta: { title: 'Тип документа'}
    },
  ]
}

export default excelReport
