<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="grid-content bg-purple">
            <div class="title_h">
              {{ $t("message.new_patient") }}
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button
                :loading="loadingButton"
                :disabled="loadingButton ? true : false"
                @click="submitPatient(true)"
                type="success"
                >{{ $t("message.save_and_close") }}</el-button
              >
              <el-button @click="resetForm('form')">{{
                $t("message.close")
              }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="grid-content">
      <div class="add_patient">
        <el-form
          ref="form"
          v-loading="loadingData"
          :model="form"
          :rules="rules"
          class="twoform"
        >
          <el-row :gutter="24">
            <el-col :span="12">
              <el-form-item :label="columns.surname.title" prop="surname">
                <el-input
                  v-model="form.surname"
                  :placeholder="columns.surname.title"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item :label="columns.first_name.title" prop="first_name">
                <el-input
                  v-model="form.first_name"
                  :placeholder="columns.first_name.title"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item :label="columns.patronymic.title" prop="patronymic">
                <el-input
                  v-model="form.patronymic"
                  :placeholder="columns.patronymic.title"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <label
                class="el-form-item__label w-100 d-flex"
                style="float: none"
                >{{ columns.phone.title }}</label
              >
              <div class="w-100 mb-4">
                <phone-number-input
                  v-model="form.phone"
                  :old="form.phone"
                  @getPhoneStatus="getPhoneStatus"
                  size="md"
                />
              </div>
            </el-col>

            <el-col :span="12">
              <el-form-item :label="columns.born_date.title">
                <el-date-picker
                  type="date"
                  v-model="form.born_date"
                  :placeholder="columns.born_date.title"
                  style="width: 100%"
                  :format="'dd.MM.yyyy'"
                  :value-format="'dd.MM.yyyy'"
                ></el-date-picker>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item :label="columns.passport_number.title">
                <crm-input
                  :placeholder="'AA1111010'"
                  :maskFormat="'passport'"
                  :inputValue="form.passport_number"
                  v-model="form.passport_number"
                  :size="'medium'"
                ></crm-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item :label="columns.code.title" prop="code">
                <!-- <el-input  v-model="form.code"  :label="columns.code.title"></el-input> -->
                <el-input v-model="form.code" :disabled="true"></el-input>
                <!-- <el-input :disabled="formCode" v-model="form.code" :value="patient_last_id" :label="columns.code.title"></el-input> -->
              </el-form-item>
            </el-col>

            <el-col :span="24">
              <div class="pol_radio">
                <el-form-item :label="columns.gender.title" prop="gender">
                  <el-radio-group v-model="form.gender">
                    <el-radio label="man" border size="medium">{{
                      $t("message.male")
                    }}</el-radio>
                    <el-radio label="woman" border size="medium">{{
                      $t("message.female")
                    }}</el-radio>
                  </el-radio-group>
                </el-form-item>
              </div>
            </el-col>

            <el-col :span="24">
              <div class="grid-content mb20">
                <el-tabs
                  v-model="activetab"
                  class="ifoData mb20"
                  type="border-card"
                >
                  <el-tab-pane
                    :label="$t('message.address_register')"
                    name="first"
                  >
                    <el-row :gutter="20">
                      <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="6">
                        <el-form-item
                          :label="$t('message.country')"
                          prop="state_id"
                        >
                          <states
                            v-model="form.state_id"
                            :state_id="form.state_id"
                          ></states>
                        </el-form-item>
                      </el-col>

                      <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="6">
                        <el-form-item :label="$t('message.city')">
                          <cities
                            v-model="form.city_id"
                            :state_id="form.state_id"
                          ></cities>
                        </el-form-item>
                      </el-col>

                      <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="6">
                        <el-form-item :label="$t('message.region')">
                          <regions
                            v-model="form.region_id"
                            :region_id="form.region_id"
                            :city_id="form.city_id"
                          ></regions>
                        </el-form-item>
                      </el-col>

                      <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="6">
                        <el-form-item :label="$t('message.street')">
                          <el-input
                            v-model="form.street"
                            :placeholder="$t('message.street')"
                          ></el-input>
                        </el-form-item>
                      </el-col>

                      <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="6">
                        <el-form-item :label="$t('message.house')">
                          <el-input
                            v-model="form.home"
                            :placeholder="$t('message.house')"
                          ></el-input>
                        </el-form-item>
                      </el-col>

                      <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="6">
                        <el-form-item :label="$t('message.flat')">
                          <el-input
                            v-model="form.flat"
                            :placeholder="$t('message.flat')"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-tab-pane>
                </el-tabs>
              </div>
            </el-col>

            <el-col :span="24" class="mt-3">
              <el-checkbox-group v-model="form.tags" size="mini">
                <el-checkbox-button
                  v-for="tag in tags"
                  :key="tag.id"
                  :label="tag.id"
                  >{{ tag.name }}</el-checkbox-button
                >
              </el-checkbox-group>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import form from "@/utils/mixins/form";
import drawerChild from "@/utils/mixins/drawer-child";
import cities from "@/components/selects/city";
import regions from "@/components/selects/region";
import states from "@/components/selects/state";
import drawer from "@/utils/mixins/drawer";
import phoneNumberInput from "@/components/crm/include/crm-phone-number-input";
import _ from "lodash";
export default {
  mixins: [drawerChild, drawer, form],
  components: {
    regions,
    cities,
    states,
    phoneNumberInput,
  },
  props: ["drawer", "reopened"],
  data() {
    return {
      formCode: false,
      socialName: false,
      activetab: "first",
      buttonIcons: "el-icon-plus",
      is_phone_valid: false,
      loadingButton: false,
      loadingData: false,
    };
  },
  watch: {
    reopened: {
      handler: function (newVal, oldVal) {
        if (newVal === true) {
          this.form = JSON.parse(JSON.stringify(this.model));
          // this.formCode = this.patient_last_id ? true : false;
          // this.form.code = this.patient_last_id + 1;
          if (!this.lastId) {
            this.getLastId().then((res) => {
              this.form.code = this.getFormatted(res.data.last_id);
            });
          } else {
            this.form.code = this.getFormatted(this.lastId);
          }
        }
      },
      immediate: true,
    },
  },
  async mounted() {
    /* Эти запросы и так отрабатывают при открытии модального окна в первый раз
    const queries = [
      this.loadSocials(),
      this.loadTags(),
      this.loadCities(),
      this.loadStates(),
    ]

    if (this.document_types && this.document_types.length === 0) {
      queries.push(this.loadDocumentTypes())
    }
    if (this.list_organization && this.list_organization.length === 0) {
      queries.push(this.loadOrganization())
    }

    await Promise.all(queries).then((values) => {
      console.log(values)
      this.loadingData = false;
    });
    */

    this.afterOpened();
  },
  computed: {
    ...mapGetters({
      socials: "socials/list",
      tags: "tags/list",
      model: "patients/model",
      patient_last_id: "patients/last_model_id",
      document_types: "documentTypes/list",
      rules: "patients/rules",
      columns: "patients/columns",
      states: "states/list",
      cities: "cities/list",
      list_organization: "organizations/list",
      lastId: "patients/lastId",
    }),
    citiesFunc: function () {
      return this.states.filter((item) => {
        return item.id == this.form.state_id;
      });
    },
  },
  methods: {
    ...mapActions({
      loadSocials: "socials/index",
      loadOrganization: "organizations/index",
      loadTags: "tags/index",
      save: "patients/store",
      editModel: "patients/show",
      empty: "patients/empty",
      loadDocumentTypes: "documentTypes/index",
      loadStates: "states/index",
      loadCities: "cities/index",
      getLastId: "patients/getLastId",
    }),
    afterOpened() {
      this.empty();
      this.form = JSON.parse(JSON.stringify(this.model));
      if (!this.lastId) {
        this.getLastId().then((res) => {
          this.form.code = this.getFormatted(res.data.last_id);
        });
      } else {
        this.form.code = this.getFormatted(this.lastId);
      }
    },
    getFormatted(num) {
      if (num < 10) {
        return "PA 000 000 00" + num;
      } else if (num >= 10 && num < 100) {
        return "PA 000 000 0" + num;
      } else if (num >= 100 && num < 1000) {
        return "PA 000 000 " + num;
      } else if (num >= 1000 && num < 10000) {
        return "PA 000 00" + new Intl.NumberFormat().format(num);
      } else if (num >= 10000 && num < 100000) {
        return "PA 000 0" + new Intl.NumberFormat().format(num);
      } else if (num >= 100000 && num < 1000000) {
        return "PA 000 " + new Intl.NumberFormat().format(num);
      } else if (num >= 1000000 && num < 10000000) {
        return "PA 00" + new Intl.NumberFormat().format(num);
      } else if (num >= 10000000 && num < 100000000) {
        return "PA 0" + new Intl.NumberFormat().format(num);
      } else if (num >= 100000000 && num < 1000000000) {
        return "PA " + new Intl.NumberFormat().format(num);
      } else if (num >= 1000000000 && num < 10000000000) {
        return "PB " + new Intl.NumberFormat().format(num);
      }
      return num;
    },
    submitPatient(close = true) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (!_.size(this.form.phone)) {
            this.is_phone_valid = true;
            this.form.phone = null;
          }
          if (this.is_phone_valid) {
            this.loadingButton = true;
            if (this.form.phone) {
              for (let key in this.form) {
                if (key == "phone" && this.form[key]) {
                  this.form[key] = this.form[key].substring(1);
                }
              }
            }

            this.save(this.form)
              .then((res) => {
                console.log(res);
                this.loadingButton = false;
                this.$refs["form"].resetFields();
                this.$alert(res);
                this.SocialName();
                this.empty();
                if (_.isFunction(this.parent().listChanged)) {
                  this.parent().listChanged();
                }
                if (this.form.social_name != "") {
                  this.loadSocials();
                }
                this.$emit("c-close", {
                  reload: true,
                  id: res.data.patient.id,
                  drawer: "drawerCreatePatient",
                });
              })
              .catch((err) => {
                this.$alert(err);
                this.loadingButton = false;
              });
          } else {
            this.$notify({
              title: this.$t("message.validation"),
              type: "error",
              offset: 130,
              message: this.$t("message.phone_number_is_not_valid"),
            });
          }
        }
      });
    },
    SocialName() {
      this.socialName = !this.socialName;
      this.buttonIcons = this.socialName
        ? (this.buttonIcons = "el-icon-minus")
        : (this.buttonIcons = "el-icon-plus");
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.$emit("c-close", { drawer: this.drawer });
    },
    getPhoneStatus({ is_valid, countryCode }) {
      this.is_phone_valid = is_valid;
      this.form.country_code = countryCode;
    },
  },
};
</script>