<template>
  <el-select
    :value="selected"
    @input="dispatch"
    :placeholder="plc || 'Район'"
    filterable
    clearable
  >
    <el-option
      v-for="item in filtered"
      :key="item.id"
      :label="item.name"
      :value="item.id"
    ></el-option>
  </el-select>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import inventory from "@/utils/mixins/components/inventory";

export default {
  mixins: [inventory],
  props: ["region_id", "city_id", "id"],
  mounted() {
    if (this.regions && this.regions.length === 0) {
      this.getInventory();
    }
  },
  watch: {
    id: {
      handler: function () {
        setTimeout(() => {
          this.selected = this.id;
        });
      },
      deep: true,
      immediate: true,
    },
    region_id: {
      handler: function (val, oldVal) {
        this.selected = val;
        this.$emit("input", this.selected);
      },
      deep: true,
      immediate: true,
    },
    city_id: function (newVal, oldVal) {
      this.selected = null;
      this.$emit("input", this.selected);
    },
  },
  computed: {
    ...mapGetters({
      regions: "regions/inventory",
    }),
    filtered: function () {
      if (this.city_id) {
        return this.regions.filter((o) => {
          return this.city_id == o.city_id;
        });
      } else {
        return this.regions;
      }
    },
  },
  methods: {
    ...mapActions({
      getInventory: "regions/inventory",
    }),
  },
};
</script>
