export const filter = {
    id: "",
    search: "",
    name: "",
    code: "",
    price: "",
    is_phy: "",
    discount_price: "",
    is_service: "",
    parent_id: "",
    created_at: "",
    updated_at: "",
};
