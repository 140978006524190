import Vue from 'vue'
/**
 * Element UI
 */
import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/ru-RU'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI,{locale});
/**
 * end element ui
 */
/**
 * Vue moment
 */
import moment from 'moment'
import 'moment/locale/uz'

Vue.use(require('vue-moment'), {
    moment
})

/**
 * money format
 */
import money from 'v-money'
Vue.use(money, {precision: 2})


/**
 * For export excel
 */
import excel from 'vue-excel-export'
Vue.use(excel);


/**
 * For alert
 */
import { notify } from './index'
Vue.prototype.$alert = notify;

/**
 * For cursor
 */
import { loadingCursor } from './index'
Vue.prototype.$loadingCursor = loadingCursor;


Vue.mixin({
    data() {
        return {
            base_url: process.env.VUE_APP_URL,
            base_url_docs: process.env.VUE_APP_URL_DOCS,
        }
    },
});