
import Layout from '@/layouts'

const categoryService = {
  path: '/partnerClinic',
  component: Layout,
  name: 'partnerClinic',
  redirect: '/partnerClinic/index',
  children: [
    {
      path: '/partnerClinic/index',
      component: () => import('@/views/partnerClinic/index'),
      name: 'partnerClinic.index',
      meta: { title: 'Партнер доктор'}
    },
  ]
}

export default categoryService
