import { i18n } from '@/utils/i18n';
export const columns = {
    id: {
        show: true,
        title: "№",
        sortable: true,
        column: 'id'
    },
    patient_id: {
        show: true,
        title: i18n.t('message.patient'),
        sortable: true,
        column: 'patient_id'
    },
    day: {
        show: true,
        title: i18n.t('message.date_0'),
        sortable: true,
        column: 'day'
    },
    service: {
        show: true,
        title: i18n.t('message.service'),
        sortable: true,
        column: 'service'
    },
    partner_doctor_id: {
        show: false,
        title: i18n.t('message.partner_doctor'),
        sortable: true,
        column: 'partner_doctor_id'
    },
    settings: {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    }
};