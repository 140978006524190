<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24" :md="9" :lg="9" :xl="9">
          <div class="grid-content bg-purple">
            <div class="title_h">
              <span>{{ $t("message.services") }}</span>
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="15" :lg="15" :xl="15">
          <div class="grid-content bg-purple-light d-flex justify-content-end">
            <div class="modalCartsevis">
              <i class="el-icon-shopping-cart-1">
                <span>
                  {{ count }}
                </span>
              </i>
            </div>
            <div class="modalsummservis">
              <i class="el-icon-wallet"></i>
              {{ amount | formatMoney }} <span></span>
            </div>
            <el-row class="button_r">
              <el-button @click="saveServiceList" type="success">{{
                $t("message.save_and_close")
              }}</el-button>
              <el-button @click="resetForm('form')">{{
                $t("message.close")
              }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="cal">
      <div>
        <div class="m-3 mt-2 mb-2 mr-3">
          <el-input
            :placeholder="$t('message.filter_keyword')"
            v-model="filterText"
          >
          </el-input>
        </div>
      </div>
      <div class="custom-tree-container">
        <div class="block">
          <el-tree
            class="filter-tree"
            :data="data"
            show-checkbox
            node-key="id"
            :props="defaultProps"
            @check-change="selected"
            :filter-node-method="filterNode"
            ref="items"
            :expand-on-click-node="true"
          >
            <span
              :style="`background: ${data.color}`"
              style="line-height: 15px"
              class="custom-tree-node"
              slot-scope="{ node, data }"
            >
              <span
                style="
                  padding-right: 100px;
                  display: inline-block;
                  white-space: break-spaces;
                  line-height: 15px;
                  font-size: 14px;
                "
                >{{ data.name }}</span
              >
              <span>
                <div v-if="data.children.length == 0">
                  {{ data.price | formatMoney }}
                </div>
              </span>
            </span>
          </el-tree>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  props: ["driver", "items"],
  data() {
    return {
      filterText: "",
      defaultProps: {
        children: "children",
        label: "label",
        price: "price",
      },
      data: [],
      selectedList: [],
    };
  },
  async created() {
    await this.fetchData();
    this.data = this.list;
  },
  async mounted() {},
  watch: {
    services: {
      handler: async function (newVal, oldVal) {
        await this.fetchData();
      },
    },
    driver: {
      handler: async function (newVal, oldVal) {
        if (this.driver === true) {
          this.data = this.list;
        } else {
          this.data = [];
        }
      },
    },
    filterText(val) {
      console.log(this.$refs.items);
      this.$refs.items.filter(val);
    },
  },
  computed: {
    ...mapGetters({
      services: "services/list",
      list: "services/relation_list",
    }),
    count: function () {
      return this.selectedList.length;
    },
    amount: function () {
      let price = this.selectedList.reduce(
        (a, b) => a + parseFloat(b.price || 0),
        0
      );
      return price.toFixed(2);
    },
  },
  methods: {
    ...mapActions({
      updateList: "categoryServices/index",
      servicesList: "services/index",
      servicesRealtionList: "services/relationList",
      saveList: "services/setlist",
    }),
    fetchData() {
      const query = { relation: true };
      this.servicesRealtionList(query)
        .then((res) => {})
        .catch((err) => {});
    },
    saveServiceList() {
      this.saveList( JSON.parse( JSON.stringify(this.selectedList) ) )
        .then((res) => {
          this.$emit("items", this.selectedList);
          this.$refs.items.setCheckedNodes([]);
          this.$refs.items.setCheckedKeys([]);
          this.resetForm("form");
        })
        .catch((err) => {
          console.log("catch");
        });
    },
    filterNode(value, data) {
      if (!value) return true;
      let dd = data.name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
      return dd;
    },
    selected() {
      this.selectedList = [];
      let item = this.$refs.items.getCheckedNodes();
      for (const i in item) {
        if (item[i]) {
          const element = item[i];
          if (element.children.length == 0) {
            this.selectedList.push(element);
          }
        }
      }
    },
    resetForm(formName) {
      this.$refs.items.setCheckedNodes([]);
      this.$refs.items.setCheckedKeys([]);
      this.$emit("c-close", { drawer: "drawerPatientService" });
    },
  },
};
</script>
<style lang="scss">
.el-tree-node__content > .custom-tree-node {
  font-size: 1.05rem;
}
.el-tree-node__children .el-tree-node__content > .custom-tree-node {
  font-size: 1rem;
}
.custom-tree-container .el-tree-node__content {
  height: auto;
}
.cal {
  .el-tree-node__content {
    position: relative;
  }
  .custom-tree-node {
    span > div {
      position: absolute;
      right: 20px;
      top: 0px;
      font-size: 16px;
      font-weight: 600;
    }
  }
}
.modalCartsevis,
.modalsummservis {
  margin: 3px 20px 0 0;
  i {
    font-size: 20px;
  }
}
.modalsummservis {
  span {
    font-size: 14px;
    color: #8e8e8e;
  }
}
.modalCartsevis {
  position: relative;
  // margin-right: 15px;
  span {
    position: absolute;
    top: -12px;
    border-radius: 50%;
    background-color: #67c23a;
    font-size: 12px;
    /* padding: 3px; */
    color: #fff;
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    right: -12px;
  }
}
</style>
