import Layout from '@/layouts'

const cost = {
  path: '/costs',
  component: Layout,
  name: 'costs',
  redirect: '/costs/index',
  children: [
    {
      path: '/costs/index',
      component: () => import('@/views/cost/index'),
      name: 'costs.index',
      meta: { title: 'Типы расхода'}
    },
  ]
}

export default cost