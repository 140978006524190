
export const mutations = { 
    SET_DASHBOARDINFO: (state, data) => {
        state.schedulePatients = data.patients
        state.stasionaryPatients = data.stasionaryPatients
        state.paymentsSum = data.payments
        state.expences = data.expences
        state.contractsCount = data.contracts
        state.formTemplates = data.forms
        state.patientScheduleCount = data.patientSchedule
        state.stasionaryPayments = data.stasionaryPayments
        state.warehouseCount = data.warehouseCount,
        state.number_of_orders_today = data.number_of_orders_today,
        state.number_of_orders_month = data.number_of_orders_month
    },
};