export default {
    components: {},
    data() {
        return {
            waitingPrint:false
        }
    },
    computed: {
    },
    mounted() {},
    watch: {},
    methods: {
        print(url,id) {
            this.waitingPrint = true;
            let vm = this;
            // var jqxhr = $.get(process.env.VUE_APP_URL + url, function() {})
            $.ajax({
                url: process.env.VUE_APP_URL + url,
                type: 'POST',
                data:{
                    id: id
                }, 
                // Fetch the stored token from localStorage and set in the header
                headers: {"Authorization": "Bearer " + localStorage.getItem('auth_token')}
              }).done(function(res) {
                    vm.waitingPrint = false;
                    const WinPrint = window.open("", "", "left=0,top=0,toolbar=0,scrollbars=0,status=0");
                    WinPrint.document.write(res);
                    WinPrint.document.close();
                    WinPrint.focus();
                    setTimeout(() => {
                        console.log("printtt");
                        WinPrint.print();
                        WinPrint.close();
                    }, 1000);
                })
                .fail(function() {
                    console.log("notprinttt");
                    alert("Не может печататься");
                    vm.waitingPrint = false;
                })
        },
        printExample(order_model,form) {
            this.waitingPrint = true;
            let vm = this;
            // var jqxhr = $.get(process.env.VUE_APP_URL + url, function() {})
            $.ajax({
                url: process.env.VUE_APP_URL + 'conclusion/print-example',
                type: 'POST',
                data: {
                    form: form,
                    order_model: order_model
                },
                // Fetch the stored token from localStorage and set in the header
                headers: {"Authorization": "Bearer " + localStorage.getItem('auth_token')}
              }).done(function(res) {
                    vm.waitingPrint = false;
                    const WinPrint = window.open("", "", "left=0,top=0,toolbar=0,scrollbars=0,status=0");
                    WinPrint.document.write(res);
                    WinPrint.document.close();
                    WinPrint.focus();
                    setTimeout(() => {
                        WinPrint.print();
                        WinPrint.close();
                    }, 1000);
                })
                .fail(function() {
                    alert("Не может печататься");
                    vm.waitingPrint = false;
                })
        },
    },
}