<template>
  <el-dropdown szie="mini" @command="handleCommand">
    <el-button size="mini" icon="el-icon-setting" round>
        <i class="el-icon-arrow-down"></i>
    </el-button>
    <el-dropdown-menu slot="dropdown" size="mini">
        <el-dropdown-item v-if="permissions.some(per => per.slug == (name+'.'+'show'))?true:true" command="edit" icon="el-icon-edit el-icon--left">
            {{ $t('message.update_setting') }}</el-dropdown-item>
        <!-- <el-dropdown-item v-if="permissions.some(per => per.slug == (name+'.'+'print'))?true:true" command="print"
            icon="el-icon-printer el-icon--left">Печать</el-dropdown-item> -->
        <!-- <el-dropdown-item v-if="permissions.includes(name+'.'+'show')" command="show"  icon="el-icon-view el-icon--left"> Показать</el-dropdown-item> -->
        <!-- <el-dropdown-item v-if="permissions.some(per => per.slug == (name+'.'+'back_material'))?true:true" command="back_material"
            icon="el-icon-refresh-left el-icon--left">Возврат сырья</el-dropdown-item>
        <el-dropdown-item v-if="permissions.some(per => per.slug == (name+'.'+'comments'))?true:true" command="comments"
            icon="el-icon-chat-line-square el-icon--left">Комментарий</el-dropdown-item> -->
        <el-dropdown-item v-if="permissions.some(per => per.slug == (name+'.'+'delete'))?true:true" command="delete"
            icon="el-icon-delete el-icon--left">{{ $t('message.delete') }}</el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
  
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: {
    model: { type: Object, required: true },
    actions: { type: Array },
    name: { type: String, required: true }
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      permissions: "auth/permissions"
    })
  },
  methods: {
    handleCommand(command) {
      if (command === "delete") {
        this.$confirm(
          "Вы действительно хотите это сделать?",
          "Предупреждение",
          {
            confirmButtonText: "Да",
            cancelButtonText: "Отмен",
            type: "warning"
          }
        )
          .then(() => {
            this.$emit(command, this.model);
          })
          .catch(() => {
            this.$message({
              type: "warning",
              message: "Операция отменена"
            });
          });
      } else {
        this.$emit(command, this.model);
      }
    }
  }
};
</script>