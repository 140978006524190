import Layout from "@/layouts"

const doctorCabinet = {
  path: "/doctor-cabinet",
  component: Layout,
  name: "doctorCabinet",
  redirect: "/doctor-cabinet/patients",
  children: [
    {
      path: "/doctor-cabinet/patients",
      component: () => import("@/views/doctorCabinet/patients"),
      name: "doctor-cabinet.patients",
      meta: { title: "Мой Кабинет" },
    },
    {
      path: "/doctor-cabinet/informal-patients",
      component: () => import("@/views/doctorCabinet/informal-patients"),
      name: "doctor-cabinet.informal-patients",
      meta: { title: "Мой Кабинет" },
    },
    {
      path: "/doctor-cabinet/AllPatients",
      component: () => import("@/views/doctorCabinet/AllPatients"),
      name: "doctor-cabinet.AllPatients",
      meta: { title: "Пациенты" },
    },

    {
      path: "/doctor-cabinet/outsource",
      component: () => import("@/views/doctorCabinet/outsource"),
      name: "outsource",
      meta: { title: "Аутсорс" },
    },
    {
      path: "/doctor-cabinet/patientMedicine",
      component: () => import("@/views/patientMedicine/index"),
      name: "doctor-cabinet.patientMedicine",
      meta: { title: "Мой Кабинет" },
    },
    {
      path: "/doctor-cabinet/patientMedicineShow",
      component: () => import("@/views/patientMedicineShow/index"),
      name: "doctor-cabinet.patientMedicineShow",
      meta: { title: "Мой Кабинет" },
    },
    {
      path: "/doctor-cabinet/conclusions/:id",
      component: () =>
        import("@/views/doctorCabinet/components/docCabPatientHistory"),
      name: "patientConclusions",
      meta: { title: "Пациенты" },
    },
    {
      path: "/laboratory-cabinet/conclusions/:id",
      component: () =>
        import("@/views/laboratoryCabinet/components/docCabPatientHistory"),
      name: "patientLaboratoryConclusions",
      meta: { title: "Пациенты" },
    },
    {
      path: "/doctor-cabinet/components/outsource",
      component: () => import("@/views/doctorCabinet/components/createOutsource"),
      name: "createOutsource",
      meta: { title: "Создать аутсорс" },
    },
  ],
}

export default doctorCabinet
