import { index, show, payPartnerDoctor, getPartnerDoctor, store, massPaid, update, getPartDoctor, getBonusDebt, destroy } from '../../../api/paymentBonus';

export const actions = {

    index({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            index(params).then(res => {
                commit("SET_LIST", res.data.data.payment_bonuses.data);
                commit("UPDATE_PAGINATION", { key: 'total', value: res.data.data.payment_bonuses.pagination.total });
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    getPartDoctor({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            getPartDoctor(params).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    getPartnerDoctor({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            getPartnerDoctor(params).then(res => {
                console.log();
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    getBonusDebt({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            getBonusDebt(params).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    show({ commit }, id) {
        return new Promise((resolve, reject) => {
            show(id).then(res => {
                commit("SET_MODEL", res.data.data.payment_bonus);
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    relationShow({ commit }, data) {
        return new Promise((resolve, reject) => {
            relation_show(data).then(res => {
                commit("SET_ORDER_MODEL", res.data.data.payment_bonus);
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    massPaid({ commit }, data) {
        return new Promise((resolve, reject) => {
            massPaid(data).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    store({ commit }, data) {
        return new Promise((resolve, reject) => {
            store(data).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    payPartnerDoctor({ commit }, data) {
        return new Promise((resolve, reject) => {
            payPartnerDoctor(data).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    update({ commit }, data) {
        return new Promise((resolve, reject) => {
            update(data).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    destroy({ commit }, id) {
        return new Promise((resolve, reject) => {
            destroy(id).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    updateSort({ commit }, sort) {
        commit("SET_SORT", sort)
    },

    updateFilter({ commit }, filter) {
        commit('SET_FILTER', JSON.parse(JSON.stringify(filter)))
    },

    updateColumn({ commit }, obj) {
        commit('UPDATE_COLUMN', obj)
    },

    updatePagination({ commit }, pagination) {
        commit('UPDATE_PAGINATION', pagination)
    },

    refreshData({ commit }) {
        return new Promise((resolve, reject) => {
            commit("REFRESH");
            resolve()
        })
    },
    empty({ commit }) {
        return new Promise((resolve, reject) => {
            commit("EMPTY_MODEL");
            resolve()
        })
    },


}
