import Layout from '@/layouts'

const rolesRouter = {
  path: '/roles',
  component: Layout,
  name: 'roles',
  redirect: '/roles/index',
  children: [
    {
      path: 'roles/index',
      component: () => import('@/views/roles/index'),
      name: 'roles.index',
      meta: { title: 'roles'}
    },
    // {
    //   path: 'roles/roles',
    //   component: () => import('@/views/roles/roles'),
    //   name: 'roles',
    //   meta: { title: 'roles'}
    // },
  ]
}

export default rolesRouter