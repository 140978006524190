<template>
  <el-select
    :value="selected"
    @input="dispatch"
    :placeholder="plc || 'Услуги'"
    filterable
    clearable
    :size="size"
    class="d-block"
  >
    <el-option
      v-for="service in services"
      :key="service.id"
      :label="service.name"
      :value="service.id"
    ></el-option>
  </el-select>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import inventory from "@/utils/mixins/components/inventory";

export default {
  mixins: [inventory],
  props: ["service_id"],
  watch: {
    service_id: {
      handler: function () {
        this.dispatch(this.service_id);
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    if (this.services && this.services.length === 0) {
      this.getInventory();
    }
  },
  computed: {
    ...mapGetters({
      services: "services/inventory",
    }),
  },
  methods: {
    ...mapActions({
      getInventory: "services/inventory",
    }),
   
  },
};
</script>