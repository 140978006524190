import Layout from '@/layouts'

const categoryService = {
  path: '/workTimes',
  component: Layout,
  name: 'workTimes',
  redirect: '/workTimes/index',
  children: [
    {
      path: '/workTimes/index',
      component: () => import('@/views/workTime/index'),
      name: 'workTimes.index',
      meta: { title: 'График работа'}
    },
  ]
}

export default categoryService
