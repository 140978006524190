import Layout from '@/layouts'

const service = {
  path: '/services',
  component: Layout,
  name: 'services',
  redirect: '/services/index',
  children: [
    {
      path: '/services/index',
      component: () => import('@/views/service/index'),
      name: 'services.index',
      meta: { title: 'Услуги'}
    },
    {
      path: '/services/priceList',
      component: () => import('@/views/service/priceList'),
      name: 'services.priceList',
      meta: { title: 'Прайс лист'}
    }
  ]
}

export default service