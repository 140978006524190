<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="10" :md="10" :lg="10" :xl="10">
          <div class="grid-content bg-purple">
            <div class="title_h">
              {{ $t("message.new_partner_clinic") }}
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button
                :loading="loadingButton"
                :disabled="loadingButton ? true : false"
                @click="submit"
                type="success"
                >{{ $t("message.save_and_close") }}</el-button
              >
              <el-button @click="resetForm('form')">{{
                $t("message.close")
              }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>

    <el-row>
      <el-col :span="24">
        <div class="grid-content">
          <div class="add_patient">
            <el-form ref="form" :model="form" :rules="rules" class="aticler_m">
              <el-row :gutter="24">
                <el-col :span="24">
                  <el-form-item :label="columns.name.title" prop="name">
                    <crm-input
                      v-model="form.name"
                      autocomplete="off"
                    ></crm-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item
                    :label="columns.phone_number.title"
                    prop="phone_number"
                  >
                    <crm-input
                      v-model="form.phone_number"
                      :maskFormat="'tel'"
                      autocomplete="off"
                    ></crm-input>
                  </el-form-item>
                </el-col>

                <el-col :span="12">
                  <el-form-item
                    :label="columns.responsible_name.title"
                    prop="responsible_name"
                  >
                    <crm-input
                      v-model="form.responsible_name"
                      autocomplete="off"
                    ></crm-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <div class="el-form-item__label w-100 text-left">
                    Logo Full партнер клиника
                  </div>
                  <div class="imgUpload">
                    <el-upload
                      :action="base_url + 'clinicImages/storeImage'"
                      :limit="1"
                      accept="image/*"
                      name="image"
                      list-type="picture-card"
                      :on-preview="handlePictureCardPreview"
                      :on-remove="handleRemove"
                      :on-success="handleAvatarSuccess"
                      :headers="{
                        Authorization: 'Bearer ' + $store.getters.token,
                      }"
                    >
                      <img v-if="form.image" :src="form.image" class="avatar" />
                      <i class="el-icon-plus"></i>
                    </el-upload>
                    <el-dialog :visible.sync="dialogVisible">
                      <img width="100%" :src="dialogImageUrl" alt />
                    </el-dialog>
                  </div>
                </el-col>
                <el-col :span="8">
                  <span class="el-form-item__label"
                    >Logo Klinka & партнер клиника</span
                  >
                  <div class="imgUpload">
                    <el-upload
                      :action="base_url + 'clinicImages/storeFullImage'"
                      :limit="1"
                      accept="image/*"
                      name="image"
                      list-type="picture-card"
                      :on-preview="handlePictureCardPreviewFull"
                      :on-remove="handleRemove"
                      :on-success="handleAvatarSuccessFull"
                      :headers="{
                        Authorization: 'Bearer ' + $store.getters.token,
                      }"
                    >
                      <img
                        v-if="form.full_image"
                        :src="form.full_image"
                        class="avatar"
                      />
                      <i class="el-icon-plus"></i>
                    </el-upload>
                    <el-dialog :visible.sync="dialogVisibleFull">
                      <img width="100%" :src="dialogFullImageUrl" alt />
                    </el-dialog>
                  </div>
                </el-col>

                <el-col :span="8">
                  <el-col :span="4">
                    <el-radio-group v-model="form.type_blank">
                      <el-radio :label="'FULL_LOGO'"
                        >Logo Full партнер клиника</el-radio
                      >
                      <el-radio :label="'HALF_LOGO'"
                        >Logo Klinka & партнер клиника</el-radio
                      >
                    </el-radio-group>
                  </el-col>
                </el-col>
                <!-- end col -->
              </el-row>
              <div class="block">
                <el-tree
                  class="filter-tree"
                  :data="data"
                  show-checkbox
                  node-key="id"
                  :filter-node-method="filterNode"
                  ref="items"
                  @node-click="handleNodeClick"
                  :expand-on-click-node="true"
                >
                  <span
                    :style="`background: ${data.color}`"
                    class="custom-tree-node"
                    slot-scope="{ node, data }"
                  >
                    <span class="aaa my-tree-khan">
                      <div>{{ data.name }}</div>
                      <el-select
                        :placeholder="$t('message.class')"
                        v-model="data.type"
                        :disabled="!node.checked"
                        size="small"
                        class="my-kh ml-3"
                        style=""
                      >
                        <el-option
                          v-for="item in classes"
                          :key="item.name"
                          :label="item.name"
                          :value="item.type"
                        >
                        </el-option>
                      </el-select>
                      <crm-input
                        :disabled="!data.type"
                        v-model="data.amount"
                        :placeholder="'sum'"
                        ref="'data_amount'+ node.id"
                        class="my-kh"
                        size="small"
                      ></crm-input>
                    </span>
                  </span>
                </el-tree>
              </div>
              <!-- end row -->
            </el-form>
          </div>
        </div>
      </el-col>
      <!-- end el-col -->
    </el-row>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      loadingButton: false,
      form: {},
      fileList: [],
      dialogImageUrl: "",
      dialogFullImageUrl: "",
      dialogVisible: false,
      dialogVisibleFull: false,
      drawerPatientService: false,
      classes: [
        {
          type: "percent",
          name: "Процент",
        },
        {
          type: "amount",
          name: "Сумма",
        },
      ],
      data: [],
      services: [],
    };
  },
  created() {
    this.form = JSON.parse(JSON.stringify(this.model));
  },
  mounted() {
    const query = { relation: true };
    this.servicesRealtionList(query)
      .then((res) => {
        this.data = res.data.services;
      })
      .catch((err) => {});

    if (this.clinics && this.clinics.length == 0) {
      this.loadClinics();
    }
  },
  computed: {
    ...mapGetters({
      rules: "partnerClinic/rules",
      clinics: "partnerClinic/list",
      model: "partnerClinic/model",
      columns: "partnerClinic/columns",
    }),
  },
  methods: {
    ...mapActions({
      servicesRealtionList: "services/relationList",
      save: "partnerClinic/store",
      selectedPartner: "partnerClinic/selectedPartnerDoctor",
    }),
    filterNode(value, data) {
      if (!value) return true;
      let dd = data.name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
      return dd;
    },
    handleNodeClick(node, data, value) {
      this.services = $.grep(this.services, function (e) {
        return e.id != data.data.id;
      });
      this.services.push(data.data);
    },
    submit(close = true) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loadingButton = true;
          this.form.services = this.services;
          this.save(this.form)
            .then((res) => {
              this.loadingButton = false;
              this.saveSelectedPartner(res.data);
              this.form = {};
              this.$alert(res);
              if (close) {
                this.$refs["form"].resetFields();
                this.$emit("c-close", {
                  reload: true,
                  drawer: "drawerPartner",
                });
              }
            })
            .catch((err) => {
              this.loadingButton = false;
              this.$alert(err);
            });
        }
      });
    },
    saveSelectedPartner(model) {
      this.selectedPartner(model)
        .then((res) => {})
        .catch((err) => {
          this.$alert(err);
        });
    },
    handleAvatarSuccessFull(res, file) {
      this.form.clinic_full_image_id = res.data.clinicImages.id;
    },
    handlePictureCardPreviewFull(file) {
      this.dialogFullImageUrl = file.url;
      this.dialogVisibleFull = true;
    },
    closeDrawerPatientService(obj) {
      if (obj.drawer) {
        this[obj.drawer] = false;
      }
    },
    handleAvatarSuccess(res, file) {
      this.form.clinic_image_id = res.data.clinicImages.id;
    },
    handleAvatarSuccessFile(res, file) {
      this.form.patient_file_id = res.data.patientFiles.id;
    },

    handleRemove(file, fileList) {
      this.destroy(file.response.data.clinicImages.id).then((res) => {});
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handlePreview(file) {
      console.log(file);
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.$emit("c-close", { reload: false, drawer: "drawerPartnerClinic" });
    },
  },
};
</script>
<style>
.my-kh input,
.my-kh {
  width: 200px !important;
  /* height: 32px  !important;
  line-height: 32px  !important; */
  margin-right: 7px !important;
  padding-right: 7px !important;
  display: block !important;
}
.filter-tree .custom-tree-node {
  width: 95%;
  display: inline-table !important;
}

.filter-tree .el-tree-node__content .aaa {
  display: flex;
}
.filter-tree .el-tree-node__content {
  height: 40px;
}
</style>