import Layout from '@/layouts'

const sickNumber = {
  path: '/sickNumber',
  component: Layout,
  name: 'sickNumber',
  redirect: '/sickNumber/index',
  children: [{
    path: '/sickNumber/index',
    component: () => import('@/views/sickNumber/index'),
    name: 'sickNumber.index',
    meta: {
      title: 'График'
    }
  }, ]
}

export default sickNumber