import Layout from '@/layouts'

const bonusService = {
  path: '/bonusServices',
  component: Layout,
  name: 'bonusServices',
  redirect: '/bonusServices/index',
  children: [
    {
      path: '/bonusServices/index',
      component: () => import('@/views/bonusService/index'),
      name: 'bonusServices.index',
      meta: { title: 'Тип документа'}
    },
  ]
}

export default bonusService
