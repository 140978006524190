import Layout from '@/layouts'

const paymentType = {
  path: '/groupsWarehouses',
  component: Layout,
  name: 'groupsWarehouses',
  redirect: '/groupsWarehouses/index',
  children: [{
    path: '/groupsWarehouses/index',
    component: () => import('@/views/groupsWarehouses/index'),
    name: 'groupsWarehouses.index',
    meta: {
      title: 'Тип Группы'
    }
  }, ]
}

export default paymentType