<template>
   <el-dropdown size="mini" split-button trigger="click" @click="refreshMessages()">
        <i class="el-icon-refresh"></i>
        <i class="el-icon-message-solid"></i>
        <span class="messageCount">{{ messageCount }}</span>
        <el-dropdown-menu slot="dropdown">

            <el-dropdown-item class="borderBottom marginTopBottom">
                <el-row>
                    <el-col :span="12">
                        <div class="text-center textWeightBold">ID Заказа</div>
                    </el-col>
                    <el-col :span="12">
                        <div class="text-center textWeightBold">Общее количество</div>
                    </el-col>
                </el-row>
            </el-dropdown-item>

            <el-dropdown-item v-for="(message, index) in messages_created" :key="index" class="borderBottom marginTopBottom">
                <el-row>
                    <el-col :span="12">
                        <div class="text-center">{{ message.sale_id }}  </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="text-center">{{ message.qty_for_sale }}</div>
                    </el-col>
                </el-row>
            </el-dropdown-item>

            <el-dropdown-item>
                <el-row>
                    <el-col :span="24">
                        <div class="text-center"><router-link :to="{name: 'notEnough.materials'}" >Просмотр все</router-link></div>
                    </el-col>
                </el-row>
            </el-dropdown-item>

        </el-dropdown-menu>
    </el-dropdown>
</template>
<script>
    export default {
        props: {
            messages: [Object, Array],
            messageCount: [Number, String],
        },
        computed: {
            messages_created: function() {
                return this.messages.filter((message) => {return message.status == 'created'})
            }
        },
        methods: {
            refreshMessages(){
                this.$emit('c-refreshMessage')
            },
        },
    }
</script>

<style>
    span.messageCount {
       background-color: #F56C6C;
        color: white;
        padding: 0px 4px;
        border: 1px solid #F56C6C;
        border-radius: 9px;
    }

    div.textWeightBold {
        font-weight: 600;
        min-width: 150px;
    }

    .borderBottom {
        border-bottom: 1px solid #DEE2E6;
    }
    
    .marginTopBottom{
        margin-top: 5px;
        margin-bottom: 5px
    }
</style>
