<template>
  <el-select
    :value="selected"
    @input="dispatch"
    :placeholder="plc || 'Страна'"
    filterable
    clearable

  >
    <el-option v-for="item in states" :key="item.id" :label="item.name" :value="item.id"></el-option>
  </el-select>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import inventory from '@/utils/mixins/components/inventory';

export default {
  mixins: [inventory],
  props: ['state_id', "id"],
  mounted() {
    if (this.states && this.states.length === 0) {
      this.getInventory();
    }
  },
  watch: {
     id: {
      handler: function () {
        setTimeout(() => {
          this.selected = this.id;
        });
      },
      deep: true,
      immediate: true,
    },
    state_id:{
       handler: function (val, oldVal) { 
          this.selected = val;
          this.$emit("input", this.selected);
       },
       deep: true,
       immediate: true
    }
  },
  computed: {
    ...mapGetters({
      states: 'states/inventory',
    }),
  },
  methods: {
    ...mapActions({
      getInventory: 'states/inventory',
    })
  },
};
</script>
