import Layout from '@/layouts'

const permissionsRouter = {
  path: '/permissions',
  component: Layout,
  name: 'permissions',
  redirect: '/permissions/index',
  children: [
    {
      path: 'permissions/index',
      component: () => import('@/views/permissions/index'),
      name: 'permissions.index',
      meta: { title: 'permissions'}
    },
  ]
}

export default permissionsRouter