<template>
  <el-select
    :value="selected"
    @input="dispatch"
    :placeholder="plc || 'Область'"
    filterable
    clearable

  >
    <el-option v-for="item in filtered" :key="item.id" :label="item.name" :value="item.id"></el-option>
  </el-select>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import inventory from '@/utils/mixins/components/inventory';

export default {
  mixins: [inventory],
  props: ['city_id', 'state_id', "id"],
  mounted() {
    if (this.cities && this.cities.length === 0) {
      this.getInventory();
    }
  },
  watch: {
    id: {
      handler: function () {
        setTimeout(() => {
          this.selected = this.id;
        });
      },
      deep: true,
      immediate: true,
    },
    city_id:{
       handler: function (val, oldVal) { 
          this.selected = val;
          this.$emit("input", this.selected);
       },
       deep: true,
       immediate: true
    },
    state_id: function(newVal,oldVal) {
      this.selected = null;
      this.$emit("input", this.selected);
    }
    
  },
  computed: {
    ...mapGetters({
      cities: 'cities/inventory',
    }),
     filtered: function() {
      if (this.state_id) {
        return this.cities.filter((o) => {
          return this.state_id == o.state_id;
        })
      }else{
        return this.cities;
      }
    }
  },
  methods: {
    ...mapActions({
      getInventory: 'cities/inventory',
    })
  },
};
</script>
