import Layout from '@/layouts'

const express = {
  path: '/express',
  component: Layout,
  name: 'express',
  redirect: '/express/index',
  children: [{
    path: '/express/index',
    component: () => import('@/views/express/index'),
    name: 'express.index',
    meta: {
      title: 'График'
    }
  }, ]
}

export default express